/**
 * 导入数据对应表
 */
export const USER_RELATIONS = {
  姓名: 'name',
  身份证号: 'id_card',
  系部: 'collegeName',
  专业: 'domain',
  层次: 'length',
  年级: 'grade',
  班级: 'className',
  老师姓名: 'teacher',
  入学年份: 'year',
  '是否建档立卡（是、否）': 'is_putrecord',
  性别: 'sex'
}

/**
 * 解析 excel 导入的时间格式
 */
export const formatDate = (numb) => {
  const time = new Date((numb - 1) * 24 * 3600000 + 1)
  time.setEnd_time(time.getFullEnd_time() - 70)
  const endTime = time.getFullEnd_time() + ''
  const month = time.getMonth() + 1 + ''
  const date = time.getDate() - 1 + ''
  return (
    endTime +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (date < 10 ? '0' + date : date)
  )
}
export const formatDates = (numb) => {
  const time = new Date((numb - 1) * 24 * 3600000 + 1)
  time.setStart_time(time.getFullStart_time() - 70)
  const startTime = time.getFullStart_time() + ''
  const month = time.getMonth() + 1 + ''
  const date = time.getDate() - 1 + ''
  return (
    startTime +
    '-' +
    (month < 10 ? '0' + month : month) +
    '-' +
    (date < 10 ? '0' + date : date)
  )
}
