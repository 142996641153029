import request from '@/utils/request'

// 在校学生数据库列表
export const stulibListAPI = (params) => {
  return request({
    url: '/stulibList',
    method: 'GET',
    params
  })
}

// 在校学生数据库删除
export const stulibDelAPI = (params) => {
  return request({
    url: '/stulibDel',
    method: 'GET',
    params
  })
}

// 在校学生数据库详情
export const stulibInfoAPI = (params) => {
  return request({
    url: '/stulibInfo',
    method: 'GET',
    params
  })
}

// 在校学生数据库修改
export const stulibSaveAPI = (data) => {
  return request({
    url: '/stulibSave',
    method: 'POST',
    data
  })
}

// 在校学生数据库添加
export const stulibAddAPI = (params) => {
  return request({
    url: '/stulibAdd',
    method: 'POST',
    params
  })
}

// 导入
export const stuimportAPI = (dataset) => {
  return request({
    url: '/stuimport',
    method: 'POST',
    data: {
      dataset
    }
  })
}

// 对比结果列表
export const resultListAPI = (params) => {
  return request({
    url: '/resultList',
    method: 'GET',
    params
  })
}
// 导出
export const reportLibraryAPI = (params) => {
  return request({
    url: '/reportLibrary',
    method: 'GET',
    params
  })
}

// 批量删除
export const stuliblotDelAPI = (data) => {
  return request({
    url: '/stuliblotDel',
    method: 'POST',
    data
  })
}

// 导出
export const reportStulibListAPI = (params) => {
  return request({
    url: '/reportStulibList',
    method: 'GET',
    params
  })
}
